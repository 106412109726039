html,
body {
  overflow-x: hidden;
}
.bGmaxb {
  top: 50px !important;
}
.page {
  background-color: #ffffff;
  position: relative;
  width: 100%;
  height: 2000px;
  overflow: hidden;
}
.box_1 {
  width: 100%;
  height: 2000px;
  background: url(./img/ps8tgj456fq3lxzpwgk4r74h0iul6x1otd647948e-6361-489f-9df4-0a4510e0594b.png) 0px -227px no-repeat;
  background-size: 1523px 2696px;
}
.box_2 {
  width: 100%;
  height: 50px;
  background: url(./img/psoad1myimvvez1ejr3eixtryx77cllqpqre0a8930c-50d0-4648-aeda-e9a4733d6fc6.png) -1px -3px no-repeat;
  background-size: 100% 53px;
  position: fixed;
  z-index: 10;
}
@media screen and (max-width: 968px) {
  .image_1 {
    width: 116px;
    height: 27px;
    margin: 9px 0 0 80px;
  }
}
.image_1 {
  width: 116px;
  height: 27px;
  margin: 9px 0 0 80px;
}
@media screen and (max-width: 900px) {
  .image_1 {
    margin: 9px 0 0 10px;
  }
}
.text_1 {
  width: 38px;
  height: 17px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 16px 0 0 58px;
}
.thumbnail_1 {
  width: 15px;
  height: 18px;
  margin: 16px 0 0 56px;
}
.text_211 {
  width: 50px;
  height: 17px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  padding-left: 10px;
}
.personInfo {
  position: fixed;
  float: right;
  top: 15px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0px;
}
.headinfo {
  position: fixed;
  float: right;
  right: 100px;
  top: 16px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.text_3 {
  width: 39px;
  height: 17px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 16px 0 0 73px;
}
.text_4 {
  width: 39px;
  height: 17px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 16px 0 0 79px;
}
.label_1 {
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 10px 0 0 0px;
  right: 40px;
  float: right;
}
.text_5 {
  position: absolute;
  cursor: pointer;
  width: 32px;
  height: 14px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 17px 15px 0 15px;
}
.text_51 {
  width: 50px;
  height: 17px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  padding-left: 10px;
  color: #ffffff;
}
.box_3 {
  position: relative;
  width: 100%;
  height: 1951px;
  margin-bottom: 1px;
}
.text_6 {
  width: 128px;
  height: 56px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 60px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  line-height: 20px;
  margin: 133px 0 0 183px;
}
.section_1 {
  width: 1099px;
  height: 462px;
  background: url(./img/psl8a7c3mcwlbhx1h0d5nkczmrz0ml5r0h5d2f705c-5778-418b-a8a8-10a134a62960.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 62px 0 0 142px;
}
.group_1 {
  width: 567px;
  height: 61px;
  margin: 106px 0 0 467px;
}
.text_7 {
  width: 164px;
  height: 34px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 36px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 7px;
}
.group_2 {
  width: 266px;
  height: 61px;
}
.group_3 {
  width: 246px;
  height: 23px;
  margin-left: 10px;
}
.text_8 {
  width: 100px;
  height: 23px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 24px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text-wrapper_1 {
  width: 140px;
  height: 19px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 4px;
}
.text_9 {
  width: 140px;
  height: 19px;
  overflow-wrap: break-word;
  color: #b1fff9;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_10 {
  width: 140px;
  height: 19px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.image-wrapper_1 {
  height: 31px;
  background: url(./img/psocz6k3fkh2l2ens9j5kv06z8dyrw31gc8a383b35-7e30-49bc-ac37-09c6d823b138.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-top: 7px;
  width: 266px;
}
.image_2 {
  width: 86px;
  height: 31px;
}
.group_4 {
  height: 182px;
  background: url(./img/psldgubv60dmqf45q4okrfrmf7cvk4h4cuue68bd5ba-7605-49e4-ad83-3c9e92f72c10.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 582px;
  margin: 33px 0 80px 452px;
}
.block_1 {
  width: 221px;
  height: 25px;
  margin: 54px 0 0 46px;
}
.text-wrapper_2 {
  width: 221px;
  height: 25px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_11 {
  width: 221px;
  height: 25px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_12 {
  width: 221px;
  height: 25px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 30px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.block_2 {
  width: 516px;
  height: 34px;
  margin: 46px 0 23px 36px;
}
.label_2 {
  width: 30px;
  height: 29px;
}
.text-wrapper_3 {
  height: 29px;
  background: url(./img/pskd87hydtvwmhpr4hwqya3r5136wcikmkfbfde9e7-b23f-456b-a8cc-fcff7d265f5b.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-left: 15px;
  width: 79px;
}
.text_13 {
  width: 8px;
  height: 12px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 9px 0 0 24px;
}
.label_3 {
  width: 30px;
  height: 29px;
  margin-left: 21px;
}
.text_14 {
  width: 104px;
  height: 15px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 7px 0 0 53px;
}
.text-wrapper_4 {
  height: 34px;
  background: url(./img/pssi9r4k414pshgyw8ol3pg948sdunb6qi10581ea43-4688-41d2-ae2a-4428300b2c92.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-left: 47px;
  width: 137px;
  padding-left: 8px;
  cursor: pointer;
}
.text_15 {
  width: 83px;
  height: 18px;
  overflow-wrap: break-word;
  color: #000000;
  font-size: 18px;
  font-family: SourceHanSansCN-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 7px 0 0 26px;
}
.section_2 {
  height: 292px;
  background: url(./img/psh6h5t9dtzwniwy8bv6d83qxw2cl5gmz634c40c1-a4e9-4072-94cd-4129210b864e.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 1099px;
  margin: 47px 0 0 142px;
}
.text-wrapper_5 {
  width: 120px;
  height: 29px;
  margin: 22px 0 0 474px;
}
.text_16 {
  width: 120px;
  height: 29px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 30px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text-wrapper_6 {
  width: 231px;
  height: 21px;
  margin: 26px 0 0 251px;
}
.text_17 {
  width: 94px;
  height: 21px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_18 {
  width: 120px;
  height: 17px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 4px;
}
.text-wrapper_7 {
  width: 549px;
  height: 22px;
  margin: 18px 0 0 249px;
}
.text_19 {
  width: 94px;
  height: 21px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_20 {
  width: 436px;
  height: 15px;
  overflow-wrap: break-word;
  color: #fefefe;
  font-size: 18px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 7px;
}
.text-wrapper_8 {
  width: 341px;
  height: 23px;
  margin: 23px 0 0 248px;
}
.text_21 {
  width: 96px;
  height: 22px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_22 {
  width: 228px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 1px;
}
.text-wrapper_9 {
  width: 432px;
  height: 22px;
  margin: 19px 0 0 248px;
}
.text_23 {
  width: 96px;
  height: 21px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_24 {
  width: 318px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text-wrapper_10 {
  width: 421px;
  height: 22px;
  margin: 21px 0 24px 247px;
}
.text_25 {
  width: 96px;
  height: 22px;
  overflow-wrap: break-word;
  color: #e5e5e5;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.text_26 {
  width: 308px;
  height: 22px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.section_3 {
  width: 1099px;
  height: 395px;
  background: url(./img/pszwnw5wa5zfmz5rrs73cq4e7g9phj19n3u10a45202-5b7b-4d25-9588-fa2caf8655c2.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 44px 0 0 142px;
}
.text_27 {
  width: 119px;
  height: 29px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 30px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 0 0 37px;
}
.text-wrapper_11 {
  width: 938px;
  height: 172px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 30px;
  margin: 42px 0 38px 82px;
}
.text_28 {
  width: 938px;
  height: 172px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 30px;
}
.paragraph_1 {
  width: 938px;
  height: 172px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  line-height: 30px;
}
.text_29 {
  width: 938px;
  height: 172px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 24px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  line-height: 30px;
}
.text-wrapper_12 {
  width: 1099px;
  height: 257px;
  background: url(./img/psccdji1wly6o7lozwn1yckn8xzi4o40npl2261384c-848a-4dfd-9f5c-3268c64631df.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 52px 0 267px 142px;
}
.text_30 {
  width: 179px;
  height: 28px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 30px;
  font-family: SourceHanSansCN-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
  margin: 28px 0 0 48px;
}
.paragraph_2 {
  width: 943px;
  height: 112px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 22px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  line-height: 30px;
  margin: 25px 0 34px 91px;
}
.section_4 {
  height: 483px;
  background: url(./img/psmmqlebdgptl1uoi9950at2r92bmuq5e562b884-f14f-4ec1-a71d-d259c896fc20.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 834px;
  position: fixed;
  left: 251px;
  top: 120px;
}
.block_3 {
  width: 655px;
  height: 31px;
  margin: 21px 0 0 138px;
}
.section_5 {
  width: 423px;
  height: 21px;
  background: url(./img/psr5zo8f3s3a3pr1zvx82b2bejiw568m1w0e09f7e3-a182-4bbf-ba36-b776c8b6b5d5.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-top: 8px;
}
.section_6 {
  height: 12px;
  background: url(./img/psvpl8z5fqaskpeolw6jouh1ib6fs33roq5f59b7e5-ae80-4499-8587-a6c686253549.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
  margin: 7px 0 0 86px;
}
.group_5 {
  height: 12px;
  background: url(./img/ps9679aq72q5fw3elly9xw7qsewcnz5tknc01715d0-d267-4389-9d9d-db7b85e1af60.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.section_7 {
  height: 12px;
  background: url(./img/pszwt4hnzj6d929oopqfhubf9scicf9g345482002-3b39-4588-87ed-c152f27222c4.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.group_6 {
  height: 12px;
  background: url(./img/psjfnnlvxzfutkbt1cr1vzc3rqe2hodc9ec7680d-a121-4b1f-995c-201767f8f287.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.box_4 {
  height: 12px;
  background: url(./img/pshp9ln5ohx6uipt6om0cxkoq68xmgfi7bf5709bb4-b514-4bf7-8e41-8e32fdacd133.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.image-wrapper_2 {
  height: 12px;
  background: url(./img/ps1rgbq7iyqoa79ah3t1j1rksrk3l0sgzxf632cd4b-761c-4c7b-b5b4-fa0c76286249.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.thumbnail_2 {
  width: 16px;
  height: 12px;
}
.section_8 {
  height: 12px;
  background: url(./img/ps9h6bkzpzlmbwhatvce2zbaf47e5q3r7v09ea4ec9-a906-4953-a06f-0f17cee06eaf.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
  margin: 7px 110px 0 195px;
}
.section_9 {
  height: 12px;
  background: url(./img/psrd19ndi4mks5yowadguutfcjao3wunvhm8edd00e2-0fe9-4f12-8555-06a839d710b8.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.group_7 {
  height: 12px;
  background: url(./img/psi7czi8cuz4b155ymz1ukm1t52np016d45b6a382-c323-4d63-9b64-95b5077ee81e.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.group_8 {
  height: 12px;
  background: url(./img/ps43p33f63u5vy2crj62qkgeerfjjkgt9275f16d40a-a94f-40aa-a1d4-06bbf457fe22.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.group_9 {
  height: 12px;
  background: url(./img/psh3s1omgzhujw5fz87wb744nc5ggufyc729b64955-bf04-44e5-89e2-ce6ca6caf84d.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.image-wrapper_3 {
  height: 12px;
  background: url(./img/psi9jr0zozm7lhx22u89pxgtbunl6d3ecmee04bce8-53de-46f7-ad7e-38441464a907.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
}
.thumbnail_3 {
  width: 16px;
  height: 12px;
}
.label_4 {
  width: 31px;
  height: 31px;
}
.block_4 {
  width: 408px;
  height: 93px;
  margin: 52px 0 286px 218px;
}
.text_31 {
  width: 49px;
  height: 23px;
  overflow-wrap: break-word;
  color: #f4eca5;
  font-size: 24px;
  font-family: SourceHanSansCN-Regular;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 70px;
}
.label_5 {
  width: 42px;
  height: 44px;
  margin: 32px 0 0 154px;
}
.image_3 {
  width: 64px;
  height: 64px;
  margin-left: 99px;
}
.block_5 {
  height: 321px;
  background: url(./img/pscng0kwvqp3bwriju3dsnbswy6fyweigu9014c123-956e-4f28-8191-f259e05cf1f4.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 348px;
  position: absolute;
  left: 418px;
  top: 128px;
}
.text-wrapper_13 {
  width: 97px;
  height: 9px;
  margin: 198px 0 0  5px;
}
.text_32 {
  width: 97px;
  height: 9px;
  overflow-wrap: break-word;
  color: #747373;
  font-size: 9px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-left: 25px;
}
.block_6 {
  width: 145px;
  height: 43px;
  margin: 70px 0 1px 202px;
}
.image_4 {
  width: 62px;
  height: 4px;
  margin-top: 38px;
}
.image-wrapper_4 {
  height: 43px;
  background: url(./img/psz7d4etoziwhb62sybxidap7stpjvhfm5a0b0a78-f383-420c-bf6c-a0b000f4aaf1.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 41px;
}
.label_6 {
  width: 32px;
  height: 33px;
  margin: 10px 0 0 9px;
}
.block_7 {
  position: absolute;
  left: 3px;
  top: 72px;
  width: 311px;
  height: 128px;
}
.image_5 {
  width: 3px;
  height: 62px;
  margin-top: 9px;
}
.thumbnail_4 {
  width: 10px;
  height: 10px;
  margin: 24px 0 0 29px;
}
.block_8 {
  width: 269px;
  height: 128px;
}
.group_10 {
  position: relative;
  width: 269px;
  height: 65px;
  background: url(./img/psefr2epagyoiao10wfmy5b6hb58mx6msd7738eecd-170c-447a-8e09-79450b73cd1e.png) 100% no-repeat;
  background-size: 100% 100%;
}
.text_33 {
  width: 23px;
  height: 12px;
  overflow-wrap: break-word;
  color: #000000;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 27px 0 0 23px;
}
.thumbnail_5 {
  width: 2px;
  height: 18px;
  margin: 24px 0 0 8px;
}
.thumbnail_6 {
  width: 13px;
  height: 9px;
  margin: 30px 0 0 5px;
}
.text_34 {
  width: 61px;
  height: 10px;
  overflow-wrap: break-word;
  color: #676363;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 118px 0 16px;
}
.thumbnail_7 {
  position: absolute;
  left: -13px;
  top: 34px;
  width: 17px;
  height: 14px;
}
.group_11 {
  position: relative;
  width: 269px;
  height: 65px;
  background: url(./img/psqhdmg46k6a82dlf2hiozx9z87ncrikmc40013787-24c0-4ff5-aff2-0651514b0407.png) 100% no-repeat;
  background-size: 100% 100%;
  margin-top: -2px;
}
.text_35 {
  width: 51px;
  height: 10px;
  overflow-wrap: break-word;
  color: #676363;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 0 0 23px;
}
.text_36 {
  width: 51px;
  height: 10px;
  overflow-wrap: break-word;
  color: #77acec;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 34px 0 110px;
}
.image-wrapper_5 {
  height: 11px;
  background: url(./img/psi9g0vwor1skdi5i15a2ad8bxeo7grxc40284cf71-55d6-4bab-9e3e-92122ff01a96.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 16px;
  position: absolute;
  left: -13px;
  top: 32px;
}
.thumbnail_8 {
  width: 10px;
  height: 13px;
  margin: -9px 0 0 3px;
}
.thumbnail_9 {
  position: absolute;
  left: 37px;
  top: 63px;
  width: 10px;
  height: 10px;
}
.block_9 {
  position: absolute;
  left: 45px;
  top: 230px;
  width: 269px;
  height: 65px;
}
.block_99 {
  position: absolute;
  left: 45px;
  top: 45px;
  width: 269px;
  height: 65px;
}
.text-wrapper_14 {
  height: 65px;
  background: url(./img/psxi9dt6m8aufbcj4x2jt027ecs5zs2hskq18547172-c12d-4acb-ae5b-b8643bd690be.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 269px;
  margin-top: 20px;
}
.text_37 {
  width: 115px;
  height: 14px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 25px 0 0 79px;
}
.thumbnail_10 {
  position: absolute;
  margin: 5px;
}
.section_10 {
  height: 483px;
  background: url(./img/ps0m5snl6sil0huttb0isioclsj8ux5a4264a4c40c7-3496-4747-8e61-4f455dba1732.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 834px;
  position: absolute;
  left: 270px;
  top: 768px;
}
.box_5 {
  position: relative;
  width: 344px;
  height: 354px;
  background: url(./img/ps2x8nuh2j8j4h31s2aue4zgos4zz4504g77bd9cf1-7306-4b08-8a26-49e6b996f3f0.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 98px 0 0 331px;
}
.group_12 {
  position: relative;
  width: 269px;
  height: 65px;
  background: url(./img/ps5tewv24lvzo9hg58wssz2jgr04vh4d0uv6d589bf2-954d-4e32-a0a4-ccf37beb9908.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 100px 0 0 39px;
}
.text_38 {
  width: 23px;
  height: 12px;
  overflow-wrap: break-word;
  color: #000000;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 27px 0 0 23px;
}
.thumbnail_11 {
  width: 13px;
  height: 9px;
  margin: 30px 0 0 15px;
}
.text_39 {
  width: 61px;
  height: 10px;
  overflow-wrap: break-word;
  color: #9e9999;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 118px 0 16px;
}
.image_6 {
  position: absolute;
  left: -13px;
  top: 24px;
  width: 16px;
  height: 25px;
}
.group_13 {
  position: relative;
  width: 269px;
  height: 65px;
  background: url(./img/pscrca18db60edcpit95y4o812l6eil7hhy591532cc-a49d-485c-adac-0e1a7f7a8692.png) 100% no-repeat;
  background-size: 100% 100%;
  margin: 46px 0 0 0px;
}
.text_40 {
  width: 34px;
  height: 10px;
  overflow-wrap: break-word;
  color: #9e9999;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 0 0 26px;
}
.text_41 {
  width: 33px;
  height: 11px;
  overflow-wrap: break-word;
  color: #9e9999;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 28px 173px 0 3px;
}
.section_11 {
  position: absolute;
  left: 0;
  top: -56px;
  width: 269px;
  height: 65px;
  background: url(./img/ps1xtuz47pwa8ofj5o0da8f9sekuddbrhub4719a04-0c37-43ed-9b8e-889e46fb4669.png) 100% no-repeat;
  background-size: 100% 100%;
}
.text_42 {
  width: 50px;
  height: 10px;
  overflow-wrap: break-word;
  color: #9e9999;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 27px 0 0 23px;
}
.text_43 {
  width: 50px;
  height: 10px;
  overflow-wrap: break-word;
  color: #77acec;
  font-size: 10px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 27px 35px 0 111px;
}
.image-wrapper_6 {
  height: 12px;
  background: url(./img/ps2ppt4t7gz9runmwrca7b1h7320sv8hgs5a0321466-840e-4251-b538-bc73fa37508b.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 17px;
  position: absolute;
  left: -14px;
  top: 31px;
}
.thumbnail_12 {
  width: 9px;
  height: 14px;
  margin: -9px 0 0 4px;
}
.image_7 {
  position: absolute;
  left: -13px;
  top: 19px;
  width: 18px;
  height: 24px;
}
.group_14 {
  height: 65px;
  background: url(./img/ps5ehanypgote1g6g38mlfv7kr4gcuj4mnrf467cbf8-e38f-4992-8d64-c2642d83356e.png) 100% no-repeat;
  background-size: 100% 100%;
  width: 269px;
  position: relative;
  margin: 1px 0 12px 39px;
}
.text_44 {
  width: 115px;
  height: 14px;
  overflow-wrap: break-word;
  color: #ffffff;
  font-size: 14px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin: 25px 0 0 74px;
}
.thumbnail_13 {
  position: absolute;
  left: 30px;
  top: -5px;
  width: 12px;
  height: 12px;
}
.thumbnail_14 {
  position: absolute;
  left: 70px;
  top: 269px;
  width: 13px;
  height: 12px;
}
.text_45 {
  position: absolute;
  left: 91px;
  top: 273px;
  width: 96px;
  height: 9px;
  overflow-wrap: break-word;
  color: #747373;
  font-size: 9px;
  font-family: SourceHanSansCN-Normal;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.image_gif_1 {
  position: absolute;
  left: 160px;
  top: 290px;
  width: 400px;
  height: 400px;
}
.image_gif_2 {
  position: absolute;
  left: 20px;
  top: 100px;
  width: 350px;
  height: 350px;
}
@media screen and (max-width: 968px) {
  .page {
    height: 1000px;
  }
  .text_3,
  .text_4,
  .image_2,
  .thumbnail_1 {
    /*  display: none; */
  }
  .text_1 {
    /*  margin: 15px 0 0 14px; */
    display: none;
  }
  .text_2 {
    /*  display: none; */
  }
  .text_5 {
    right: 0px;
  }
  .text_51 {
    right: 50px;
  }
  .section_4 {
    height: 483px;
    background: url(./img/psmmqlebdgptl1uoi9950at2r92bmuq5e562b884-f14f-4ec1-a71d-d259c896fc20.png) 100% no-repeat;
    background-size: 100% 100%;
    width: 100vw;
    position: fixed;
    margin: auto;
    top: -100px;
    left: -200px;
    right: 0px;
    bottom: 0px;
    -webkit-transform: scale(0.5, 0.5) !important;
    transform: scale(0.5, 0.5) !important;
  }
  .text_6 {
    font-size: 30px;
    margin: 60px 0 0 30px;
  }
  .section_1 {
    position: absolute;
    -webkit-transform: scale(0.3, 0.3) !important;
    transform: scale(0.3, 0.3) !important;
    left: -500px;
    top: -100px;
  }
  .image_gif_1 {
    position: absolute;
    left: 20px;
    top: 30px;
    width: 400px;
    height: 400px;
  }
  .label_1 {
    margin: 0;
  }
  .section_2 {
    position: relative;
    -webkit-transform: scale(0.3, 0.3) !important;
    transform: scale(0.3, 0.3) !important;
    left: -500px;
    top: 10px;
  }
  .section_3 {
    position: relative;
    -webkit-transform: scale(0.3, 0.3) !important;
    transform: scale(0.3, 0.3) !important;
    left: -500px;
    top: -260px;
  }
  .text-wrapper_12 {
    position: relative;
    -webkit-transform: scale(0.3, 0.3) !important;
    transform: scale(0.3, 0.3) !important;
    left: -500px;
    top: -530px;
  }
}
