.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  position: relative;
  /* 设为相对定位 */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}
.icons-container {
  position: absolute;
  top: 0;
  left: 10%;
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}
.modal-image {
  width: 80%;
  /* 或根据需求调整 */
  max-height: 80vh;
}
.modal-logo,
.ios_download,
.apk_download {
  position: relative;
}
.modal-logo {
  width: 24.88%;
  height: auto;
}
.ios_download {
  width: 272px;
}
.apk_download {
  width: 272px;
}
.d_span {
  color: #ffffff;
  font-size: 24px;
}
.d_span2 {
  color: #ffffff;
  font-size: 20px;
}
